import React, { useMemo } from 'react';
import cc from 'classcat';

import Button from '@/components/Button';
import Image from '@/components/Image';
import { getIcon } from '@/lib/IconHelper';

import styles from './styles.module.scss';

interface Props {
  tile: PromotionTilesFragment;
  smallMobile?: boolean;
}

const PromotionTile = ({ tile, smallMobile }: Props) => {
  const iconOrLogo = useMemo(() => {
    if (tile.iconOrLogo?.[0]) {
      if ('icon' in tile.iconOrLogo[0]) {
        return getIcon(tile.iconOrLogo[0].icon as IconFragment);
      } else if ('logo' in tile.iconOrLogo[0]) {
        return <Image {...(tile.iconOrLogo[0].logo![0] as AssetFragment)} />;
      }
    }
    return null;
  }, [tile]);
  return (
    <div
      className={cc([
        styles.promotionTile,
        tile.promotionTileVariants ? styles[tile.promotionTileVariants] : styles['brandPrimary'],
        smallMobile && styles.smallMobile,
      ])}
    >
      {iconOrLogo && <div className={styles.imageIcon}>{iconOrLogo}</div>}
      <div className={styles.text}>
        {!!tile.title && <p className={styles.heading}>{tile.title}</p>}
        {!!tile.textarea && <p className={styles.body}>{tile.textarea}</p>}
      </div>
      {!!tile.customLinkField && (
        <Button
          size="large"
          href={tile.customLinkField.url!}
          target={tile.customLinkField.target || undefined}
          styleOverride={styles.buttonStyle}
          type={tile.promotionTileVariants ? PromotionTileVariantButtonMap[tile.promotionTileVariants] : 'action'}
        >
          {tile.customLinkField.text}
          {!!tile.icon && getIcon(tile.icon)}
        </Button>
      )}
    </div>
  );
};

// Map available variants from craftCMS promotionTileVariants dropdown -> <Button/> type
const PromotionTileVariantButtonMap: Record<string, ButtonType> = {
  brandPrimary: 'foreground',
  brandSecondary: 'on-foreground-transparent',
  foregroundPrimary: 'brand',
  foregroundSecondary: 'on-foreground-transparent',
  foregroundTertiary: 'action-light',
  actionPrimary: 'on-action',
  actionSecondary: 'on-action-transparent',
  actionLightPrimary: 'action',
  actionLightSecondary: 'action-transparent',
  actionLighten: 'action',
  onForegroundPrimary: 'on-foreground',
};

export default PromotionTile;
